import { LocationDiscoveryController } from "@features/LocationDiscovery/LocationDiscoveryController";
import { ACTIVE_BRANDS_QUERY, ActiveBrands } from "@features/LocationDiscovery/hooks/useActiveBrands";
import { LOCATIONS_QUERY } from "@features/Locations/hooks/useLocations";
import { LocationWithDistance, OrderType } from "@types";
import serverClient from "@utils/apolloClient";
import { ChangeOrderTypeFeature } from "@utils/contexts/OrderTypeContext";
import { useTrackWithFlags } from "@utils/hooks/useTrackWithFlags";
import { GetStaticProps } from "next";
import { useState } from "react";
import smoothscroll from "smoothscroll-polyfill";
interface HomeStaticProps {
  locations: LocationWithDistance[];
  activeBrandsIds?: ActiveBrands[];
}
export const getStaticProps: GetStaticProps<HomeStaticProps> = async () => {
  const [{
    data: locationsData,
    error: locationsError
  }, {
    data: activeBrandsData,
    error: activeBrandsError
  }] = await Promise.all([serverClient.query({
    query: LOCATIONS_QUERY,
    fetchPolicy: "network-only"
  }), serverClient.query({
    query: ACTIVE_BRANDS_QUERY,
    fetchPolicy: "network-only"
  })]);
  if (locationsError || activeBrandsError) {
    throw new Error(`Failed to fetch data during revalidation of order.lk: ${locationsError} ${activeBrandsError}`);
  }
  const locations = (locationsData?.locations as LocationWithDistance[]).filter(loc => loc.is_active);
  return {
    props: {
      locations: locations,
      activeBrandsIds: activeBrandsData?.active_brands
    },
    revalidate: 5 * 60 // 5 minutes
  };
};

// fixes smooth scrolling on Edge, Safari (desktop) and Safari/Chrome (iOS)
if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}
const Home = ({
  locations,
  activeBrandsIds
}: HomeStaticProps) => {
  const [orderType, setOrderType] = useState(OrderType.PICKUP);
  const {
    track
  } = useTrackWithFlags();
  const handleChangeOrderTypeClicked = (orderType: OrderType, feature: ChangeOrderTypeFeature) => {
    setOrderType(orderType);
    track({
      event: "Changed Order Type",
      properties: {
        orderType,
        feature
      }
    });
  };
  return <LocationDiscoveryController locations={locations} show activeBrandsIds={activeBrandsIds} orderType={orderType} handleChangeOrderTypeClicked={handleChangeOrderTypeClicked} data-sentry-element="LocationDiscoveryController" data-sentry-component="Home" data-sentry-source-file="index.tsx" />;
};
Home.displayName = "Location Discovery";
export default Home;